define("discourse/plugins/discourse-circles/discourse/components/circles/search-fields/multi-select", ["exports", "@ember/helper", "@ember/utils", "discourse-common/utils/decorators", "select-kit/components/multi-select", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/dropdown", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/wire-frame", "@ember/component", "@ember/template-factory"], function (_exports, _helper, _utils, _decorators, _multiSelect, _dropdown, _wireFrame, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchFieldMultiSelect extends _dropdown.default {
    onValueChanged(value1) {
      this.args.controller.set(this.args.filter.queryParameter, (0, _utils.isEmpty)(value1) ? null : value1);
    }
    static #_ = (() => dt7948.n(this.prototype, "onValueChanged", [_decorators.bind]))();
    static #_2 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <SearchFieldWireFrame
          ...attributes
          @controller={{@controller}}
          @filter={{@filter}}
          @value={{@value}}
        >
          <MultiSelect
            name={{@filter.name}}
            @nameProperty={{null}}
            @valueProperty={{null}}
            @value={{readonly @value}}
            @onChange={{this.onValueChanged}}
            @content={{this.content}}
            @options={{hash
              translatedNone=this.noneLabel
              caretUpIcon="caret-up"
              caretDownIcon="caret-down"
            }}
          />
        </SearchFieldWireFrame>
      
    */
    {
      "id": "86rO8gsw",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[17,1]],[[\"@controller\",\"@filter\",\"@value\"],[[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],[[16,3,[30,3,[\"name\"]]]],[[\"@nameProperty\",\"@valueProperty\",\"@value\",\"@onChange\",\"@content\",\"@options\"],[null,null,[28,[31,0],[[30,4]],null],[30,0,[\"onValueChanged\"]],[30,0,[\"content\"]],[28,[32,2],null,[[\"translatedNone\",\"caretUpIcon\",\"caretDownIcon\"],[[30,0,[\"noneLabel\"]],\"caret-up\",\"caret-down\"]]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"&attrs\",\"@controller\",\"@filter\",\"@value\"],false,[\"readonly\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/search-fields/multi-select.js",
      "scope": () => [_wireFrame.default, _multiSelect.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SearchFieldMultiSelect;
});