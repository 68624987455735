define("discourse/plugins/discourse-circles/discourse/lib/stacked-icon-renderer", ["exports", "virtual-dom", "discourse-common/lib/attribute-hook", "discourse-common/lib/escape", "I18n"], function (_exports, _virtualDom, _attributeHook, _escape, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SVG_NAMESPACE = "http://www.w3.org/2000/svg";

  // based on core default resolver
  // custom renderer that allows to stack icons #use inside the SVG

  const renderer = {
    string(icon, params) {
      const ids = handleIconId(icon).map(iconId => (0, _escape.default)(iconId));
      let html = `<svg class='${(0, _escape.default)(iconClasses(icon, params))} svg-string'`;
      if (params.label) {
        html += " aria-hidden='true'";
      } else if (params["aria-label"]) {
        html += ` aria-hidden='false' aria-label='${(0, _escape.default)(params["aria-label"])}'`;
      }
      html += ` xmlns="${SVG_NAMESPACE}">`;
      ids.forEach((iconId, index) => html += `<use href="#${iconId}" class="${`svg-use-${index}`}" />`);
      html += `</svg>`;
      if (params.label) {
        html += `<span class="sr-only">${(0, _escape.default)(params.label)}</span>`;
      }
      if (params.title) {
        html = `<span class="svg-icon-title" title="${(0, _escape.default)(_I18n.default.t(params.title))}">${html}</span>`;
      }
      if (params.translatedTitle) {
        html = `<span class="svg-icon-title" title="${(0, _escape.default)(params.translatedTitle)}">${html}</span>`;
      }
      return html;
    },
    node(icon, params) {
      const ids = handleIconId(icon);
      const classes = iconClasses(icon, params) + " svg-node";
      const svg = (0, _virtualDom.h)("svg", {
        attributes: {
          class: classes,
          "aria-hidden": true
        },
        namespace: SVG_NAMESPACE
      }, ids.map((iconId, index) => (0, _virtualDom.h)("use", {
        href: (0, _attributeHook.default)("http://www.w3.org/1999/xlink", `#${(0, _escape.default)(iconId)}`),
        attributes: {
          class: `svg-use-${index}`
        },
        namespace: SVG_NAMESPACE
      })));
      if (params.title) {
        return (0, _virtualDom.h)("span", {
          title: params.title,
          attributes: {
            class: "svg-icon-title"
          }
        }, [svg]);
      } else {
        return svg;
      }
    }
  };
  var _default = _exports.default = renderer;
  function handleIconId(icon) {
    let iconId = icon.replacementId || icon.id || "";
    return iconId?.split(",").map(v => v.trim().replace(" unpinned", ""));
  }
  function iconClasses(icon, params) {
    // "notification." is invalid syntax for classes, use replacement instead
    const renderedId = icon.replacementId && icon.id.includes("notification.") ? icon.replacementId : icon.id;
    const ids = renderedId?.split(",").map(v => v.trim()) || [];
    const dClass = ids.join("-");
    const dIconStacked = ids.length > 1 ? `d-icon-stacked d-icon-stacked-${ids.length}` : "";
    let classNames = `fa d-icon d-icon-${dClass} ${dIconStacked} svg-icon`;
    if (params && params["class"]) {
      classNames += " " + params["class"];
    }
    return classNames;
  }
});