define("discourse/plugins/discourse-circles/discourse/components/circles/card/stats", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "discourse/components/plugin-outlet", "discourse/lib/formatter", "discourse-common/helpers/i18n", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _pluginOutlet, _formatter, _i18n, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.activityTypes = void 0;
  const activityTypes = _exports.activityTypes = ["day", "week", "month", "year", "all_time"];
  class CircleMemberStats extends _component.default {
    get activityStats() {
      const stat1 = activityTypes.find(type1 => this.args.model.category[`topics_${type1}`] > 0) || "all_time";
      return _I18n.default.t(`discourse_circles.circle.topics_stats.${stat1}`, {
        count: this.args.model.category[`topics_${stat1}`]
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "activityStats", [(0, _object.computed)(...activityTypes.map(type1 => `args.model.category.topics_${type1}`))]))();
    get createdAt() {
      return (0, _formatter.longDate)(this.model.created_at);
    }
    static #_2 = (() => dt7948.n(this.prototype, "createdAt", [(0, _object.computed)("model.created_at")]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="circles-card__stats">
          <PluginOutlet
            @name="circles-card-stats"
            @outletArgs={{hash model=@model}}
          >
            <div class="circles-card__stats-item" data-stat-type="members">
              {{i18n
                "discourse_circles.circle.about.members"
                count=@model.member_group.user_count
              }}
            </div>
            <div class="circles-card__stats-item" data-stat-type="topics">
              <span>{{this.activityStats}}</span>
            </div>
            {{#if @model.category.unreadTopics}}
              <div class="circles-card__stats-item" data-stat-type="unread_topics">
                <a
                  href={{@model.category.unreadUrl}}
                  title={{i18n
                    "topic.unread_topics"
                    count=@model.category.unreadTopics
                  }}
                  class="badge unread-topics badge-notification"
                >
                  {{i18n
                    "filters.unread.lower_title_with_count"
                    count=@model.category.unreadTopics
                  }}
                </a>
              </div>
            {{/if}}
            {{#if @model.category.newTopics}}
              <div class="circles-card__stats-item" data-stat-type="new_topics">
                <a
                  href={{@model.category.newUrl}}
                  title={{i18n "topic.new_topics" count=@model.category.newTopics}}
                  class="badge new-topics badge-notification"
                >
                  {{i18n
                    "filters.new.lower_title_with_count"
                    count=@model.category.newTopics
                  }}
                </a>
              </div>
            {{/if}}
          </PluginOutlet>
        </div>
      
    */
    {
      "id": "maaXBdev",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"circles-card__stats\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"circles-card-stats\",[28,[32,1],null,[[\"model\"],[[30,1]]]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"circles-card__stats-item\"],[14,\"data-stat-type\",\"members\"],[12],[1,\"\\n          \"],[1,[28,[32,2],[\"discourse_circles.circle.about.members\"],[[\"count\"],[[30,1,[\"member_group\",\"user_count\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"circles-card__stats-item\"],[14,\"data-stat-type\",\"topics\"],[12],[1,\"\\n          \"],[10,1],[12],[1,[30,0,[\"activityStats\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,1,[\"category\",\"unreadTopics\"]],[[[1,\"          \"],[10,0],[14,0,\"circles-card__stats-item\"],[14,\"data-stat-type\",\"unread_topics\"],[12],[1,\"\\n            \"],[10,3],[15,6,[30,1,[\"category\",\"unreadUrl\"]]],[15,\"title\",[28,[32,2],[\"topic.unread_topics\"],[[\"count\"],[[30,1,[\"category\",\"unreadTopics\"]]]]]],[14,0,\"badge unread-topics badge-notification\"],[12],[1,\"\\n              \"],[1,[28,[32,2],[\"filters.unread.lower_title_with_count\"],[[\"count\"],[[30,1,[\"category\",\"unreadTopics\"]]]]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[41,[30,1,[\"category\",\"newTopics\"]],[[[1,\"          \"],[10,0],[14,0,\"circles-card__stats-item\"],[14,\"data-stat-type\",\"new_topics\"],[12],[1,\"\\n            \"],[10,3],[15,6,[30,1,[\"category\",\"newUrl\"]]],[15,\"title\",[28,[32,2],[\"topic.new_topics\"],[[\"count\"],[[30,1,[\"category\",\"newTopics\"]]]]]],[14,0,\"badge new-topics badge-notification\"],[12],[1,\"\\n              \"],[1,[28,[32,2],[\"filters.new.lower_title_with_count\"],[[\"count\"],[[30,1,[\"category\",\"newTopics\"]]]]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@model\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/card/stats.js",
      "scope": () => [_pluginOutlet.default, _helper.hash, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CircleMemberStats;
});