define("discourse/plugins/discourse-circles/discourse/components/circles/membership-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse/lib/ajax-error", "discourse/lib/cookie", "I18n", "discourse/plugins/discourse-circles/discourse/components/modal/membership-reason", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _concatClass, _ajaxError, _cookie, _I18n, _membershipReason, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CirclesMembershipButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "circles", [_service.inject]))();
    #circles = (() => (dt7948.i(this, "circles"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "updatingMembership", [_tracking.tracked], function () {
      return false;
    }))();
    #updatingMembership = (() => (dt7948.i(this, "updatingMembership"), void 0))();
    get data() {
      if (this.canJoinCircle) {
        return {
          className: "circles-join-button btn-primary",
          icon: "user-plus",
          label: "discourse_circles.circle.actions.join",
          onClick: this.joinCircle
        };
      } else if (this.canManageCircle) {
        return {
          className: "circles-manage-button btn-warning",
          icon: "wrench",
          label: "discourse_circles.circle.actions.manage",
          onClick: this.manageCircle
        };
      } else if (this.canLeaveCircle) {
        return {
          className: "circles-leave-button btn-danger",
          icon: "wrench",
          label: "discourse_circles.circle.actions.leave",
          onClick: this.leaveCircle
        };
      } else if (this.canCancelMembershipRequest) {
        return {
          className: "circles-cancel-request-button btn-default",
          icon: "user-slash",
          label: "discourse_circles.circle.actions.cancel_request",
          onClick: this.cancelMembershipRequest
        };
      } else if (this.canRequestMembership) {
        return {
          className: "circles-request-button btn-primary",
          icon: "user-plus",
          label: "discourse_circles.circle.actions.request",
          onClick: this.showRequestMembershipForm
        };
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "data", [(0, _object.computed)("canJoinCircle", "canManageCircle", "canLeaveCircle", "canCancelMembershipRequest", "canRequestMembership")]))();
    get className() {
      return this.data?.className;
    }
    static #_10 = (() => dt7948.n(this.prototype, "className", [(0, _object.computed)("data.className")]))();
    get icon() {
      return this.data?.icon;
    }
    static #_11 = (() => dt7948.n(this.prototype, "icon", [(0, _object.computed)("updatingMembership", "data.icon")]))();
    get label() {
      const translatedLabel1 = _I18n.default.t(this.data?.label);
      return this.updatingMembership ? _I18n.default.t(this.data?.label + "_loading", {
        defaultValue: translatedLabel1
      }) : translatedLabel1;
    }
    static #_12 = (() => dt7948.n(this.prototype, "label", [(0, _object.computed)("updatingMembership", "data.label")]))();
    get onClick() {
      return this.data?.onClick;
    }
    static #_13 = (() => dt7948.n(this.prototype, "onClick", [(0, _object.computed)("data.onClick")]))();
    get canJoinCircle() {
      return this.args.model.member_group.public_admission && !this.userIsCircleUser;
    }
    static #_14 = (() => dt7948.n(this.prototype, "canJoinCircle", [(0, _object.computed)("args.model.member_group.public_admission", "userIsCircleUser")]))();
    get canLeaveCircle() {
      return this.args.model.member_group.public_exit && this.userIsCircleUser;
    }
    static #_15 = (() => dt7948.n(this.prototype, "canLeaveCircle", [(0, _object.computed)("args.model.member_group.public_exit", "userIsCircleUser")]))();
    get canRequestMembership() {
      return this.args.model.member_group.allow_membership_requests && !this.userIsCircleUser;
    }
    static #_16 = (() => dt7948.n(this.prototype, "canRequestMembership", [(0, _object.computed)("args.model.member_group.allow_membership_requests", "userIsGroupUser")]))();
    get canCancelMembershipRequest() {
      return this.args.model.is_membership_request_pending;
    }
    static #_17 = (() => dt7948.n(this.prototype, "canCancelMembershipRequest", [(0, _object.computed)("args.model.is_membership_request_pending")]))();
    get canManageCircle() {
      return !!this.args.model.is_circle_owner;
    }
    static #_18 = (() => dt7948.n(this.prototype, "canManageCircle", [(0, _object.computed)("args.model.is_circle_owner")]))();
    get userIsCircleUser() {
      return !!this.args.model.is_circle_member;
    }
    static #_19 = (() => dt7948.n(this.prototype, "userIsCircleUser", [(0, _object.computed)("args.model.is_circle_member")]))();
    async #join() {
      this.updatingMembership = true;
      try {
        const circle1 = this.args.model;
        const group1 = circle1.member_group;
        await group1.join();
        circle1.set("is_circle_member", true);
        group1.set("is_group_user", true);
        this.appEvents.trigger("circle:join", circle1);
        this.appEvents.trigger("group:join", group1);
      } catch (e1) {
        (0, _ajaxError.popupAjaxError)(e1);
      } finally {
        this.updatingMembership = false;
      }
    }
    async #leave() {
      this.updatingMembership = true;
      try {
        const circle1 = this.args.model;
        await circle1.leave();
        circle1.set("is_circle_member", false);
        this.appEvents.trigger("circle:leave", circle1);
        circle1.groups.forEach(group1 => {
          group1.set("is_group_user", false);
          this.appEvents.trigger("group:leave", group1);
        });
      } catch (e1) {
        (0, _ajaxError.popupAjaxError)(e1);
      } finally {
        this.updatingMembership = false;
      }
    }
    async #cancelRequest() {
      this.updatingMembership = true;
      try {
        const circle1 = this.args.model;
        await circle1.cancelMembershipRequest();
        circle1.set("is_membership_request_pending", false);
        this.appEvents.trigger("circle:membership_request_cancelled", circle1);
      } catch (e1) {
        (0, _ajaxError.popupAjaxError)(e1);
      } finally {
        this.updatingMembership = false;
      }
    }
    #showLoginModal(evt1) {
      evt1?.preventDefault();
      evt1?.stopPropagation();
      (0, _cookie.default)("destination_url", window.location.href);
      this.args.showLogin();
    }
    joinCircle(evt1) {
      if (this.currentUser) {
        if (this.siteSettings.discourse_circles_ask_confirmation_before_user_joins_circle) {
          return this.dialog.confirm({
            title: _I18n.default.t("discourse_circles.circle.confirm_join.title", {
              name: this.args.model.name
            }),
            message: _I18n.default.t("discourse_circles.circle.confirm_join.question", {
              name: this.args.model.name
            }),
            confirmButtonLabel: "discourse_circles.circle.confirm_join.confirm",
            cancelButtonLabel: "cancel",
            class: "circles-confirm-join-dialog",
            didConfirm: () => this.#join()
          });
        } else {
          this.#join();
        }
      } else {
        this.#showLoginModal(evt1);
      }
    }
    static #_20 = (() => dt7948.n(this.prototype, "joinCircle", [_object.action]))();
    manageCircle() {
      this.router.transitionTo("circle.manage", this.args.model.slug_path_with_id);
    }
    static #_21 = (() => dt7948.n(this.prototype, "manageCircle", [_object.action]))();
    leaveCircle() {
      if (this.args.model.member_group.public_admission && !this.siteSettings.discourse_circles_always_ask_confirmation_before_user_leaves_circle) {
        this.#leave();
      } else {
        return this.dialog.confirm({
          title: _I18n.default.t("discourse_circles.circle.confirm_leave.title", {
            name: this.args.model.name
          }),
          message: _I18n.default.t("discourse_circles.circle.confirm_leave.question", {
            name: this.args.model.name
          }),
          confirmButtonLabel: "discourse_circles.circle.confirm_leave.confirm",
          cancelButtonLabel: "cancel",
          class: "circles-confirm-leave-dialog",
          didConfirm: () => this.#leave()
        });
      }
    }
    static #_22 = (() => dt7948.n(this.prototype, "leaveCircle", [_object.action]))();
    showRequestMembershipForm(evt1) {
      const circle1 = this.args.model;
      if (this.currentUser) {
        this.modal.show(_membershipReason.default, {
          model: {
            circle: circle1,
            // because controllers are singletons. ensures that the reason will
            // be empty
            reason: "",
            mode: "request",
            onSubmit: async reason1 => {
              this.updatingMembership = true;
              try {
                await circle1.requestMembership(reason1);
                circle1.set("is_membership_request_pending", true);
                this.appEvents.trigger("circle:request_membership", circle1);
                this.dialog.alert({
                  message: _I18n.default.t("discourse_circles.circle.membership_request.success", {
                    circle_name: circle1.name
                  }),
                  class: "circles-alert-membership-requested-dialog"
                });
              } catch (e1) {
                (0, _ajaxError.popupAjaxError)(e1);
              } finally {
                this.updatingMembership = false;
              }
            }
          }
        });
      } else {
        this.#showLoginModal(evt1);
      }
    }
    static #_23 = (() => dt7948.n(this.prototype, "showRequestMembershipForm", [_object.action]))();
    cancelMembershipRequest() {
      if (!this.siteSettings.discourse_circles_always_ask_confirmation_before_user_leaves_circle) {
        this.#cancelRequest();
      } else {
        return this.dialog.confirm({
          title: _I18n.default.t("discourse_circles.circle.confirm_cancel_request.title", {
            name: this.args.model.name
          }),
          message: _I18n.default.t("discourse_circles.circle.confirm_cancel_request.question", {
            name: this.args.model.name
          }),
          confirmButtonLabel: "discourse_circles.circle.confirm_cancel_request.confirm",
          cancelButtonLabel: "discourse_circles.circle.confirm_cancel_request.abort",
          class: "circles-confirm-cancel-request-dialog",
          didConfirm: () => this.#cancelRequest()
        });
      }
    }
    static #_24 = (() => dt7948.n(this.prototype, "cancelMembershipRequest", [_object.action]))();
    static #_25 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.data}}
          <DButton
            class={{concatClass "circles-membership-button" this.className}}
            ...attributes
            @action={{this.onClick}}
            @disabled={{this.updatingMembership}}
            @icon={{this.icon}}
            @isLoading={{this.updatingMembership}}
            @translatedLabel={{this.label}}
          />
        {{else}}
          {{yield}}
        {{/if}}
      
    */
    {
      "id": "H2W1jhlb",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"data\"]],[[[1,\"      \"],[8,[32,0],[[16,0,[28,[32,1],[\"circles-membership-button\",[30,0,[\"className\"]]],null]],[17,1]],[[\"@action\",\"@disabled\",\"@icon\",\"@isLoading\",\"@translatedLabel\"],[[30,0,[\"onClick\"]],[30,0,[\"updatingMembership\"]],[30,0,[\"icon\"]],[30,0,[\"updatingMembership\"]],[30,0,[\"label\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/membership-button.js",
      "scope": () => [_dButton.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CirclesMembershipButton;
});