define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/wire-frame", ["exports", "discourse-common/helpers/i18n", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/validation-tip", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _i18n, _validationTip, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FormFieldWireFrame = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="field {{@fieldName}}">
      <label class="control-label {{if @required 'required'}}" for={{@fieldName}}>
        {{#if @title}}
          <span>
            <span>{{@title}}</span>
            {{#if @required}}
              <span class="required_flag">
                {{i18n "discourse_circles.form.required_field_indicator"}}
              </span>
            {{/if}}
          </span>
        {{/if}}
        {{#if @description}}
          <span class="control-label__info">
            {{@description}}
            {{#unless @title}}
              {{#if @required}}
                <span class="required_flag">
                  {{i18n "discourse_circles.form.required_field_indicator"}}
                </span>
              {{/if}}
            {{/unless}}
          </span>
        {{/if}}
      </label>
      {{yield}}
      <ValidationTip
        @validation={{@validation}}
        @value={{@value}}
        @maxLength={{@maxLength}}
      />
    </div>
  
  */
  {
    "id": "j+Axg9Mi",
    "block": "[[[1,\"\\n  \"],[10,0],[15,0,[29,[\"field \",[30,1]]]],[12],[1,\"\\n    \"],[10,\"label\"],[15,0,[29,[\"control-label \",[52,[30,2],\"required\"]]]],[15,\"for\",[30,1]],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[10,1],[12],[1,\"\\n          \"],[10,1],[12],[1,[30,3]],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"            \"],[10,1],[14,0,\"required_flag\"],[12],[1,\"\\n              \"],[1,[28,[32,0],[\"discourse_circles.form.required_field_indicator\"],null]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,4],[[[1,\"        \"],[10,1],[14,0,\"control-label__info\"],[12],[1,\"\\n          \"],[1,[30,4]],[1,\"\\n\"],[41,[51,[30,3]],[[[41,[30,2],[[[1,\"              \"],[10,1],[14,0,\"required_flag\"],[12],[1,\"\\n                \"],[1,[28,[32,0],[\"discourse_circles.form.required_field_indicator\"],null]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[18,8,null],[1,\"\\n    \"],[8,[32,1],null,[[\"@validation\",\"@value\",\"@maxLength\"],[[30,5],[30,6],[30,7]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@fieldName\",\"@required\",\"@title\",\"@description\",\"@validation\",\"@value\",\"@maxLength\",\"&default\"],false,[\"if\",\"unless\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/form-fields/wire-frame.js",
    "scope": () => [_i18n.default, _validationTip.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = FormFieldWireFrame;
});