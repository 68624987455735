define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom", ["exports", "@glimmer/component", "@ember/helper", "@ember/utils", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/confirm", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/dropdown", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/multi-select", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/text", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _utils, _confirm, _dropdown, _multiSelect, _text, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COMPONENTS = {
    confirm: _confirm.default,
    dropdown: _dropdown.default,
    multiselect: _multiSelect.default,
    text: _text.default
  };
  let nameCleanerRegex;
  class FormFieldCustom extends _component.default {
    get classNames() {
      const baseClasses1 = ["custom-field", `custom-field--${this.args.field.type}`];
      const fieldName1 = this.args.field.name;
      if (fieldName1) {
        const fieldClassName1 = fieldName1.replace(/\s+/g, "-").replace(nameCleanerRegex, "").toLowerCase();
        if (!(0, _utils.isEmpty)(fieldClassName1)) {
          return [...baseClasses1, `custom-field__${fieldClassName1}`];
        }
      }
      return baseClasses1;
    }
    get component() {
      return COMPONENTS[this.args.field.type];
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <this.component
          class={{this.classNames}}
          ...attributes
          @field={{@field}}
          @buffer={{@buffer}}
          @value={{mut (get @buffer @field.name)}}
          @validation={{@validation}}
          @hidePlaceholder={{true}}
        />
      
    */
    {
      "id": "1LHiOU2S",
      "block": "[[[1,\"\\n    \"],[8,[30,0,[\"component\"]],[[16,0,[30,0,[\"classNames\"]]],[17,1]],[[\"@field\",\"@buffer\",\"@value\",\"@validation\",\"@hidePlaceholder\"],[[30,2],[30,3],[28,[31,0],[[28,[32,0],[[30,3],[30,2,[\"name\"]]],null]],null],[30,4],true]],null],[1,\"\\n  \"]],[\"&attrs\",\"@field\",\"@buffer\",\"@validation\"],false,[\"mut\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom.js",
      "scope": () => [_helper.get],
      "isStrictMode": true
    }), this))();
  }
  // regex from user-field/base.js
  // It is being defined at the bottom of the file using a variable defined with let
  // because defining it before the template tag is breaking both prettier and eslint parsers
  _exports.default = FormFieldCustom;
  nameCleanerRegex = /[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g;
});