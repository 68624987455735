define("discourse/plugins/discourse-circles/discourse/components/modal/membership-reason", ["exports", "@glimmer/tracking", "@ember/component", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/d-modal-cancel", "discourse/helpers/route-action", "I18n", "@ember/template-factory"], function (_exports, _tracking, _component, _helper, _object, _service, _dButton, _dModal, _dModalCancel, _routeAction, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CirclesMembershipReasonModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "submitting", [_tracking.tracked]))();
    #submitting = (() => (dt7948.i(this, "submitting"), void 0))();
    get rawTitle() {
      return _I18n.default.t(`discourse_circles.circle.membership_${this.mode}.title`, {
        circle_name: this.model.circle.name
      });
    }
    get labelText() {
      return _I18n.default.t(`discourse_circles.circle.membership_${this.mode}.reason`);
    }
    static #_3 = (() => dt7948.n(this.prototype, "labelText", [(0, _object.computed)("mode")]))();
    get disclaimerText() {
      return _I18n.default.t(`discourse_circles.circle.membership_${this.mode}.reason_disclaimer`, {
        username: this.model.username,
        defaultValue: ""
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "disclaimerText", [(0, _object.computed)("mode")]))();
    get disableSubmit() {
      return this.submitting;
    }
    static #_5 = (() => dt7948.n(this.prototype, "disableSubmit", [(0, _object.computed)("submitting")]))();
    get reason() {
      return this.model.reason;
    }
    set reason(value1) {
      this.model.reason = value1;
    }
    get mode() {
      return this.model.mode || "request";
    }
    submit() {
      this.submitting = true;
      try {
        this.model.onSubmit(this.reason);
        this.closeModal();
      } finally {
        this.submitting = false;
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "submit", [_object.action]))();
    static #_7 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          @title={{this.rawTitle}}
          @tagName="form"
          class="request-circles-membership-form circles-membership-reason-modal"
        >
          <:body>
            <div class="control-group">
              <label>
                {{this.labelText}}
              </label>
    
              <Textarea
                disabled={{this.saving}}
                @value={{this.reason}}
                rows={{4}}
              />
    
              <div class="disclaimer">
                {{this.disclaimerText}}
              </div>
            </div>
          </:body>
    
          <:footer>
            <DButton
              @action={{this.submit}}
              @disabled={{this.disableSubmit}}
              @label={{concat
                "discourse_circles.circle.membership_"
                this.mode
                ".submit"
              }}
              class="btn-primary"
            />
    
            <DModalCancel @close={{routeAction "closeModal"}} />
          </:footer>
        </DModal>
      
    */
    {
      "id": "u/4OCy3k",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"request-circles-membership-form circles-membership-reason-modal\"]],[[\"@closeModal\",\"@title\",\"@tagName\"],[[30,1],[30,0,[\"rawTitle\"]],\"form\"]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n          \"],[10,\"label\"],[12],[1,\"\\n            \"],[1,[30,0,[\"labelText\"]]],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[8,[32,1],[[16,\"disabled\",[30,0,[\"saving\"]]],[16,\"rows\",4]],[[\"@value\"],[[30,0,[\"reason\"]]]],null],[1,\"\\n          \"],[10,0],[14,0,\"disclaimer\"],[12],[1,\"\\n            \"],[1,[30,0,[\"disclaimerText\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-primary\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"submit\"]],[30,0,[\"disableSubmit\"]],[28,[32,3],[\"discourse_circles.circle.membership_\",[30,0,[\"mode\"]],\".submit\"],null]]],null],[1,\"\\n\\n        \"],[8,[32,4],null,[[\"@close\"],[[28,[32,5],[\"closeModal\"],null]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/modal/membership-reason.js",
      "scope": () => [_dModal.default, _component.Textarea, _dButton.default, _helper.concat, _dModalCancel.default, _routeAction.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CirclesMembershipReasonModal;
});